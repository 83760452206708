import React from "react"
import { graphql } from "gatsby"

import DefaultLayout from "../components/default-layout"
import Meta from "../components/meta"

const ThanksPage = ({ data }) => {
  const { title, author, siteUrl } = data.site.siteMetadata
  return (
    <DefaultLayout
      siteTitle={title}
      author={author}
    >
      <Meta
        title="Thanks!!"
        meta={[
          { name: "robots", content: "noindex, nofollow" },
          { name: "googlebot", content: "noindex, nofollow" },
        ]}
        link={[
          { rel: "canonical", href: siteUrl }
        ]}
      />
      Thanks!!
    </DefaultLayout>
  )
}

export default ThanksPage;
export const thanksQuery = graphql`
  query thanksQuery {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
  }
`;
